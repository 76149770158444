import { useState } from 'react';

import Logo from '@hiredigital/ui/Logo/PolyLogo';

import Link from '../../Link';
import { AbmLinks } from './AbmLinks';
import MobileMenu from './MobileMenu';

import Styles from '../Header.module.scss';

const Default = ({ onMenuToggle, linksToHomePage }) => {
  const [anchorClicked, setAnchorClicked] = useState(false);

  return (
    <>
      <Link href='/' className={Styles.logoLink} title={`Poly`}>
        <Logo className={Styles.logo} site={'4'} />
      </Link>
      <MobileMenu
        onMenuToggle={onMenuToggle}
        watchAnchor={anchorClicked}
        resetAnchor={() => setAnchorClicked(false)}>
        <AbmLinks>
          <Link
            className={Styles.headerLink}
            title=''
            href={(linksToHomePage ? '/' : '') + '#Case_Studies'}
            onClick={() => setAnchorClicked(true)}>
            Case Studies
          </Link>
          <Link
            className={Styles.headerLink}
            title=''
            href={(linksToHomePage ? '/' : '') + '#Our_Expertise'}
            onClick={() => setAnchorClicked(true)}>
            Our Expertise
          </Link>
          <Link
            className={Styles.headerLink}
            title=''
            href={(linksToHomePage ? '/' : '') + '#Our_People'}
            onClick={() => setAnchorClicked(true)}>
            Our People
          </Link>
        </AbmLinks>
      </MobileMenu>
    </>
  );
};

export default Default;
