import { memo, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '../Link';
import ListLink from '../ListLink/ListLink';
import Logo from '@hiredigital/ui/Logo/PolyLogo';
import FooterNav from './Nav';
import Facebook from '../Svg/Facebook';
import Linkedin from '../Svg/Linkedin';
import Twitter from '../Svg/Twitter';
import Button from '@components/Abm/Button';

import { locales } from '../../../data/contact';
import Styles from './Footer.module.scss';
import { setDefaultAccentColors } from '@components/Abm/defaults';

const Footer = memo(({ darkFooter, data, locale, footer, className }) => {
  useEffect(setDefaultAccentColors, []);

  const selectedLocale = locales.find((l) => l.locale === locale) || data;
  const listLinkType = darkFooter ? ListLink.Type.WHITE : ListLink.Type.DARKGRAY;
  return (
    <footer
      className={classNames(
        Styles.wrapper,
        darkFooter ? Styles.darkblue : className ? className : Styles.defaultBackground
      )}
      data-test-id='footer'>
      <div className={Styles.sitemap}>
        <div className={classNames(Styles.container, Styles.containerTop)}>
          <div className={Styles.description}>
            <Link to='/' title={`Poly`}>
              <Logo
                className={Styles.logo}
                type={darkFooter ? Logo.Type.WHITE : Logo.Type.DARKGRAY}
                site={'4'}
              />
            </Link>
            <p className={Styles.descriptionText}>
              Poly helps businesses build and enhance their digital capabilities with a world-class
              network of digital marketers, developers, and designers. Clients leverage on-demand
              teams to gain control of key priorities, plan their seasonal resources, and launch
              pilots to success.
            </p>
            <Button
              button={{ label: 'Contact Us', url: '/schedule-call' }}
              className={Styles.footerButton}
            />
          </div>
          <div className={Styles.flexSpacer} />

          <FooterNav
            title='Related Sites'
            className={classNames(Styles.footerNav, Styles.clientFooterNav)}>
            <ListLink type={listLinkType} className={Styles.navLink} to='https://hiredigital.com/'>
              {`Hire Digital`}
            </ListLink>
          </FooterNav>

          <FooterNav
            title='Company'
            className={classNames(Styles.footerNav, Styles.clientFooterNav)}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/#Our_Expertise'>
              {`Our Expertise`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/schedule-call'>
              {`Contact Us`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/privacy'>
              {`Privacy`}
            </ListLink>
          </FooterNav>
        </div>
      </div>

      <div className={classNames(Styles.contact, Styles.container)}>
        <div className={classNames(Styles.section, Styles.leftSection)}>
          <a
            data-test-id='footer-em'
            className={classNames(Styles.contactLink)}
            href={
              selectedLocale
                ? `mailto:${selectedLocale.email}`
                : `mailto:${process.env.NEXT_PUBLIC_CONTACT_EMAIL}`
            }
            title='Enquiry'>
            {selectedLocale ? selectedLocale.email : process.env.NEXT_PUBLIC_CONTACT_EMAIL}
          </a>
        </div>
        <div className={Styles.section}>
          <a
            data-test-id='footer-ph'
            className={classNames(Styles.contactLink)}
            href={`tel:${
              footer?.internationalNumber || selectedLocale?.phone || '+1 (617) 528-0943'
            }`}
            title='phone'>
            {footer?.internationalNumber || selectedLocale?.phone || '+1 (617) 528-0943'}
          </a>
        </div>
        <div className={classNames(Styles.section, Styles.rightSection)}>
          {/*
          <a
            className={Styles.linkIcon}
            data-test-id='footer-fb'
            href={`${process.env.NEXT_PUBLIC_FACEBOOK_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Poly Facebook`}
            target='_blank'>
            <Facebook className={Styles.icon} />
          </a>
          <a
            className={Styles.linkIcon}
            data-test-id='footer-tw'
            href={`${process.env.NEXT_PUBLIC_TWITTER_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Poly Twitter`}
            target='_blank'>
            <Twitter className={Styles.icon} />
          </a>
          */}
          <a
            className={Styles.linkIcon}
            data-test-id='footer-li'
            href={`${process.env.NEXT_PUBLIC_LINKEDIN_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Poly LinkedIn`}
            target='_blank'>
            <Linkedin className={Styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  darkFooter: PropTypes.bool,
  data: PropTypes.object,
  locale: PropTypes.string,
};

export default Footer;
