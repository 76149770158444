import { memo, Fragment, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import classNames from 'classnames';

import Menu from './components/Abm';

import Styles from './Header.module.scss';

/*
  There are two types of heroes. One for hero pages (dark background) and pages (light background)
  There are several variants of pages
    - talent
    - blog (insights)
    - enterprise
    - default
*/
// export const Types = {
//   HERO: Styles.hero,
//   PAGES: Styles.pages,
// };

// We memoize the menu in order to prevent it from re-rendering when type is changed
const MemoMenu = memo(Menu);

const Header = memo(({ linksToHomePage, isTransparent }) => {
  const [sticky, setSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [ref, inView, entry] = useInView({ threshold: 1 });
  useEffect(() => {
    if (entry) {
      setSticky(!inView);
    }
  }, [inView, entry]);

  const toggleShowMenu = (option) => {
    setShowMenu(option);
  };

  // !isFixed && (sticky || showBackground) && Styles.sticky,
  return (
    <Fragment>
      <div ref={ref} />
      <header
        className={classNames(
          Styles.container,
          Styles.isFixed,
          isTransparent || Styles.isBlack,
          Styles.isOffset,
          showMenu && Styles.menuIsOpen
        )}>
        <div className={Styles.wrapper}>
          <div className={Styles.header}>
            <MemoMenu onMenuToggle={toggleShowMenu} linksToHomePage={linksToHomePage} />
          </div>
        </div>
      </header>
    </Fragment>
  );
});

Header.propTypes = {};

Header.Styles = Styles;

export default Header;
