import Link from '../../Link';
import classNames from 'classnames';
import Styles from '../Header.module.scss';

export const AbmLinks = ({ children }) => {
  return (
    <div className={Styles.navList}>
      {/*      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/clients'>
          {`Clients`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/enterprise'>
          {`Enterprise`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/why'>
          {`Why On Demand`}
        </Link>
      </div>
*/}
      {/*CTA COES HERE*/}
      <div className={Styles.navItem}>{children}</div>
    </div>
  );
};
